import { LocationAutoComplete } from "./components/location-auto-complete";
import { LocationFieldProps } from "./location-field.types";

export const LocationField = ({
  label,
  error,
  required,
  types = ["(cities)"],
  setValue,
  name,
  disabled,
  isCityName = false,
  placeholder,
  value,
}: LocationFieldProps) => {
  let definitivePlaceholder = isCityName ? "ex: Chicago, IL" : "ex: 00000-000";

  if (placeholder) {
    definitivePlaceholder = placeholder;
  }

  return (
    <div className="w-full">
      <div className="flex w-full flex-col text-neutral-primary ">
        {label && (
          <span className="text-sm/6 font-normal text-neutral-primary">
            {label}
            {required && "*"}
          </span>
        )}
        <LocationAutoComplete
          placeholder={definitivePlaceholder}
          setValue={value => {
            setValue(name, value, { shouldValidate: true });
          }}
          value={(value as string) || ""}
          name={name}
          disabled={disabled}
          types={types}
        />
        {error && (
          <span className="mt-1 flex items-center text-xs text-status-critical">
            {error}
          </span>
        )}
      </div>
    </div>
  );
};
