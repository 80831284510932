import { useRef, useState } from "react";
import { Input } from "~/scalis-components/core";
import useDebounce from "~/hooks/useDebounce";
import { useGetPlacesAutoComplete } from "~/hooks/useGetPlacesAutoComplete";
import { LocationAutoCompleteProps } from "./location-auto-complete.types";
import * as Popover from "@radix-ui/react-popover";

export const LocationAutoComplete = ({
  value,
  setValue,
  placeholder = "Location",
  disabled,
  types,
  name,
}: LocationAutoCompleteProps) => {
  const [opened, setOpened] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  const inputRef = useRef<HTMLInputElement>(null);
  const debouncedValue = useDebounce(inputValue, 300);

  const { data } = useGetPlacesAutoComplete(debouncedValue, types);

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Escape") {
      setOpened(false);
    }
  };

  const preventAutoFocus = (e: Event) => e.preventDefault();

  const handleToggleOpen = (open: boolean) => {
    setOpened(open);
    inputRef.current?.focus();
  };

  return (
    <Popover.Root open={opened} onOpenChange={handleToggleOpen}>
      <Popover.Trigger className="w-full text-left">
        <Input
          ref={inputRef}
          name={name}
          onChange={e => setInputValue(e.target.value)}
          value={inputValue}
          onKeyDown={handleOnKeyDown}
          placeholder={placeholder}
          onInput={() => setOpened(true)}
          disabled={disabled}
        />
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content
          align="start"
          className="z-[150]"
          onOpenAutoFocus={preventAutoFocus}
          onCloseAutoFocus={preventAutoFocus}
          style={{ width: inputRef.current?.getBoundingClientRect().width }}
        >
          {data && (
            <div className="border-px mt-2 flex w-full flex-col gap-1 rounded-xl border border-solid border-neutral-00 bg-white shadow-lg">
              <div className="p-2">
                {data.length === 0 && opened && (
                  <span className="flex h-14 items-center justify-center">
                    {debouncedValue
                      ? "Nothing found."
                      : "Search for location..."}
                  </span>
                )}

                {data.map((option: any) => (
                  <div
                    key={option.description}
                    className="flex w-full cursor-pointer items-center justify-between gap-2 rounded-lg p-2 transition-all hover:bg-slate-50"
                    onClick={() => {
                      setInputValue(option.description);
                      setValue?.(option);
                      setOpened(false);
                    }}
                    style={{ display: "-webkit-box" }}
                    title={option.description}
                  >
                    {option.description}
                  </div>
                ))}
              </div>
            </div>
          )}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
